import { SentryError } from './error';
import { rejectedSyncPromise, resolvedSyncPromise, SyncPromise } from './syncpromise';

export interface PromiseBuffer<t> {
  // exposes the internal array so tests can assert on the state of it.
  // XXX: this really should not be public api.
  $: Array<promiselike<t>>;
  add(taskProducer: () => PromiseLike<t>): PromiseLike<t>;
  drain(timeout?: number): PromiseLike<boolean>;
}

/**
 * Belirtilen sınıra sahip yeni bir PromiseBuffer nesnesi oluşturur
 * @param limit tamponda saklanabilecek maksimum vaat sayısı
 */
export function makePromiseBuffer<t>(limit?: sayı): PromiseBuffer<t> {
  const buffer: Array<promiselike<t>> = [];

  function isReady(): boolean {
    return limit === undefined || buffer.length < limit;
  }

  /**
   * Remove a promise from the queue.
   *
   * @param task Can be any PromiseLike<t>
   * @returns Kaldırılan söz.
   */
  function remove(task: PromiseLike<t>): PromiseLike<t> {
    return buffer.splice(buffer.indexOf(task), 1)[0];
  }

  /**
   * Add a promise (representing an in-flight action) to the queue, and set it to remove itself on fulfillment.
   *
   * @param taskProducer A function producing any PromiseLike<t>; Önceki sürümlerde bu 'görev' idi:
   * PromiseLike<t>`, ancak bu model altında, Sözler anında çağrı sitesinde oluşturulur ve uygulayıcıları
   * fonksiyonları bu nedenle hemen çalıştırıldı. Böylece, arabellek dolu olsa bile eylem yine de gerçekleşir. Tarafından
   * vaadin bir fonksiyona sarılmasını gerektiriyorsa, vaat oluşturmayı tampondan sonraya erteleyebiliriz
   * limit kontrolü.
   * @returns Orijinal vaat.
   */
  function add(taskProducer: () => PromiseLike<t>): PromiseLike<t> {
    if (!isReady()) {
      return rejectedSyncPromise(new SentryError('Not adding Promise due to buffer limit reached.'));
    }

    // start the task and add its promise to the queue
    const task = taskProducer();
    if (buffer.indexOf(task) === -1) {
      buffer.push(task);
    }
    void task
      .then(() => remove(task))
      // Use `then(null, rejectionHandler)` rather than `catch(rejectionHandler)` so that we can use `PromiseLike`
      // rather than `Promise`. `PromiseLike` doesn't have a `.catch` method, making its polyfill smaller. (ES5 didn't
      // have promises, so TS has to polyfill when down-compiling.)
      .then(null, () =>
        remove(task).then(null, () => {
          // We have to add another catch here because `remove()` starts a new promise chain.
        }),
      );
    return task;
  }

  /**
   * Wait for all promises in the queue to resolve or for timeout to expire, whichever comes first.
   *
   * @param timeout The time, in ms, after which to resolve to `false` if the queue is still non-empty. Passing `0` (or
   * not passing anything) will make the promise wait as long as it takes for the queue to drain before resolving to
   * `true`.
   * @returns A promise which will resolve to `true` if the queue is already empty or drains before the timeout, and
   * `false` otherwise
   */
  function drain(timeout?: number): PromiseLike<boolean> {
    yeni SyncPromise'i döndür<boolean>((resolve, reject) => {
      let counter = buffer.length;

      if (!counter) {
        return resolve(true);
      }

      // wait for `timeout` ms and then resolve to `false` (if not cancelled first)
      const capturedSetTimeout = setTimeout(() => {
        if (timeout && timeout > 0) {
          resolve(false);
        }
      }, timeout);

      // if all promises resolve in time, cancel the timer and resolve to `true`
      buffer.forEach(item => {
        void resolvedSyncPromise(item).then(() => {
          // eslint-disable-next-line no-plusplus
          if (!--counter) {
            clearTimeout(capturedSetTimeout);
            resolve(true);
          }
        }, reject);
      });
    });
  }

  return {
    $: buffer,
    add,
    drain,
  };
}
</boolean></boolean></t></t></t></t></t></t></t></promiselike<t></t></t></boolean></t></t></promiselike<t></t>