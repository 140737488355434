/**
 * Sözü tüketir ve reddettiğinde hatayı günlüğe kaydeder.
 * @param söz Unutmak için bir söz.
 */
eslint-disable-next-line @typescript-eslint/no-explicit-any
dışa aktarma işlevi unutma (söz: PromiseLike<any>): void {
  void promise.then(null, e => {
    // TODO: Use a better logging mechanism
    // eslint-disable-next-line no-console
    console.error(e);
  });
}
</any>