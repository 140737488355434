import { React, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import moment from "moment";
import * as Sentry from "@sentry/browser";
import validateModalOuibounce from "utils/validateModalOuibounce";
import { AiOutlineClose } from "react-icons/ai";

const ModalOuibounce = ({ onClose, showModal }) => {
  const [form, setForm] = useState({
    email: "",
    firstname: "",
    lastname: "",
    phoneNumber: "",
    message: "",
    value: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formSent, setFormSent] = useState(false);
  const [errors, setErrros] = useState({});
  function gtag_report_conversion(url) {
    var callback = function () {
      if (typeof url != "undefined") {
        window.location = url;
      }
    };
    gtag("event", "conversion", {
      send_to: "AW-11382787557/3yTwCN3Uy-8YEOWb3rMq",
      event_callback: callback,
    });
    return false;
  }
  const handleFormSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const { errors, valid } = validateModalOuibounce(form);
    if (!valid) {
      setErrros(errors);
      setIsSubmitting(false);
    } else {
      gtag_report_conversion();
      fetch("/api/mail", {
        method: "post",
        body: JSON.stringify({
          recipient: "info@booklinik.com",
          templateId: "d-c2b02b9d8e8448da934509a658d1e884",
          dynamicTemplateData: {
            email: form.email,
            firstname: form.firstname,
            lastname: form.lastname,
            phoneNumber: form.phoneNumber,
            value: value,
            datetime: moment(new Date()).format("LLLL"),
          },
        }),
      })
        .then(() => {
          setFormSent(true);

          fetch("/api/mail", {
            method: "post",
            body: JSON.stringify({
              recipient: form.email,
              templateId: "d-335fc72a314142a18dce581cd7ebf059",
              dynamicTemplateData: {
                email: form.email,
                firstname: form.firstname,
                lastname: form.lastname,
                phoneNumber: form.phoneNumber,
                value: value,
                datetime: moment(new Date()).format("LLLL"),
              },
            }),
          });
        })
        .catch((error) => {
          Sentry.captureException(error);
        })
        .finally(() => {
          setIsSubmitting(false);
          setErrros({});
        });
    }
  };
  const handleFormChange = (e) => {
    setForm({
      ...form,
      [e.target.name]:
        e.target.name === "phoneNumber"
          ? e.target.value
              .replace(/[^0-9.]/g, "")
              .replace(/(\..*?)\..*/g, "$1")
              .trim()
          : e.target.value,
    });
  };

  const handlePhoneNumber = (phone) => {
    setForm({
      ...form,
      phoneNumber: `+${phone}`,
    });
  };

  const getInitialState = () => {
    const value = "";
    return value;
  };

  const [value, setValue] = useState(getInitialState);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  if (!showModal) return null;
  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center z-50 ">
      {
        //
      }
      <div id="contactform" className="py-40 mt-15 px-3 max-h-1 ">
        <div className="md:mt-20 md:my-10 rounded-xl bg-shamrock  gap-0 text-white placeholder-white p-5 h-[calc(100vh-3gitrem)] md:h-5/6 overflow-y-scroll ">
          <div className="p-2 ">
            <span className="cursor-pointer">
              <aioutlineclose className="hover:text-gray-500" size="{20}" onClick="{onClose}"></aioutlineclose>
            </span>
          </div>
          <div className="grid gric-cols-1 py-14 mx-4  xl:mx-auto pt-3  px-6">
            <div className="space-y-6 h-full">
              <div className="text-white">
                <h2 className="text-4xl mb-2">İndirimden yararlanın!</h2>
                <h2 className="text-3xl mb-2 mt-6">
                  Remplissez le formulaire aujourd&apos;hui et recevez 150€ de
                  réduction sur votre intervention{" "}
                </h2>
              </div>

              <form onSubmit="{handleFormSubmit}">
                <div className="space-y-6 mt-10">
                  {!formSent ? (
                    <>
                      <div className="grid grid-cols-2 gap-8">
                        <div>
                          <p className="uppercase text-sm mb-2">İlk ad</p>
                          <input type="text" className="{`w-full" bg-transparent="" border-b="" outline-none="" placeholder-white="" ${="" errors="" &&="" errors.firstname="" ?="" "border-red-600="" "="" :="" "border-white"="" }="" p-3`}="" placeholder="İlk ad" name="firstname" value="{form.firstname}" onChange="{handleFormChange}">
                          { errors && errors.firstname ? (
                            <span className="text-red-600 text-sm mt-3">
                              {errors.firstname}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div>
                          <p className="uppercase text-sm mb-2">
                            Soyadı
                          </p>
                          <input type="text" className="{`w-full" bg-transparent="" border-b="" outline-none="" placeholder-white="" ${="" errors="" &&="" errors.lastname="" ?="" "border-red-600="" "="" :="" "border-white"="" }="" p-3`}="" placeholder="Soyadı" name="lastname" value="{form.lastname}" onChange="{handleFormChange}">
                          {hatalar && hatalar.soyadi ? (
                            <span className="text-red-600 text-sm mt-3">
                              {hatalar.soyadı}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center">
                        <div className="{`${" errors="" &&="" errors.phoneNumber="" ?="" "error-input"="" :="" ""="" }`}="">
                          <p className="uppercase text-sm mb-2">
                            Telefon numarası
                          </p>
                          <phoneinput country="{&quot;fr&quot;}" value="{form.phoneNumber}" onChange="{(phone)" ==""> handlePhoneNumber(phone)}
                          />
                          {errors && errors.phoneNumber ? (
                            <span className="text-red-600 text-sm mt-3">
                              {errors.phoneNumber}
                            </span>
                          ) : (
                            ""
                          )}
                        </phoneinput></div>

                        <div>
                          <p className="uppercase text-sm mb-2">E-posta adresiniz</p>
                          <input type="email" className="{`w-full" bg-transparent="" border-b="" outline-none="" placeholder-white="" ${="" errors="" &&="" errors.email="" ?="" "border-red-600="" "="" :="" "border-white"="" }="" p-3`}="" placeholder="E-posta" name="email" value="{form.email}" onChange="{handleFormChange}">
                          {hatalar & errors.email ? (
                            <span className="text-red-600 text-sm mt-3">
                              {errors.email}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div>
                        <label className="uppercase text-sm mb-2">
                          Operasyonunuzu seçin
                          <select value="{value}" onChange="{handleChange}" className="w-full bg-transparent border-b outline-none placeholder-white  text-xl hover:text-shamrock block hover:bg-gray-100 w-full p-5 py-3 cursor-pointer">
                            <option value="Greffe de cheveux">
                              Saç ekimi
                            </option>
                            <option value="Chirurgie mammaire">
                              Meme cerrahisi
                            </option>
                            <option value="Chirurgie du nez">
                              Burun ameliyatı
                            </option>
                            <option value="Chirurgie des fesses">
                              Kalça ameliyatı
                            </option>
                            <option value="Chirurgie du visage">
                              Yüz cerrahisi
                            </option>
                            <option value="Chirurgie du corps">
                              Vücut cerrahisi
                            </option>
                            <option value="Medecine esthétique">
                              Estetik tıp
                            </option>
                            <option value="Chirurgie de l oeil">
                              Göz cerrahisi
                            </option>
                            <option value="Chirurgie dentaires">
                              Diş cerrahisi
                            </option>
                            <option value="Autre">Başka </option>
                          </select>
                          {değer == "Diğer" ? (
                            <span className=" text-sm mt-3">
                              <div>
                                <input type="text" className="w-full bg-transparent border-b outline-none placeholder-white py-5" placeholder="Belirtmek..." name="operation" value="{form.operation}" onChange="{handleFormChange}">
                              </div>
                            </span>
                          ) : (
                            ""
                          )}
                        </label>
                      </div>

                      <div className="flex-wrap  ">
                        <button type="submit" className="mx-2  float-right rounded bg-white bg-opacity-10 border border-white-500 px-5 py-3 transition hover:bg-opacity-100 hover:text-shamrock" disabled="{isSubmitting}">
                          Gönder
                        </button>
                      </div>
                    </>
                  ) : (
                    <div className="w-full h-full flex items-center justify-center">
                      <p className="text-center">
                        Mesajınız gönderildi. Biz göndereceğiz
                        mümkün olan en kısa sürede size geri döneceğiz.
                      </p>
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalOuibounce;
