/**
 * @license
 * Telif Hakkı 2017 Google LLC
 *
 * Apache Lisansı, Sürüm 2.0 ("Lisans") altında lisanslanmıştır;
 * Bu dosyayı Lisansa uygun olmadıkça kullanamazsınız.
 * Lisansın bir kopyasını şu adresten edinebilirsiniz:
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Yürürlükteki yasalar gerektirmedikçe veya yazılı olarak kabul edilmedikçe, yazılım
 * Lisans kapsamında dağıtılan "OLDUĞU GİBİ" esasına göre dağıtılır,
 * AÇIK veya ZIMNİ HİÇBİR GARANTİ VEYA KOŞUL OLMAKSIZIN.
 * İzinleri yöneten belirli bir dil için Lisansa bakın ve
 * Lisans kapsamındaki sınırlamalar.
 */

dışa aktarma işlevi şunları içerir:<t extends="" object="">(obj: T, key: string): boolean {
  return Object.prototype.hasOwnProperty.call(obj, key);
}

export function safeGet<t extends="" object,="" K="" keyof="" T="">(
  obj: T,
  key: K
): T[K] | undefined {
  if (Object.prototype.hasOwnProperty.call(obj, key)) {
    return obj[key];
  } else {
    return undefined;
  }
}

export function isEmpty(obj: object): obj is {} {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      return false;
    }
  }
  return true;
}

export function map<k extends="" string,="" V,="" U="">(
  obj: { [key in K]: V },
  fn: (value: V, key: K, obj: { [key in K]: V }) => U,
  contextObj?: unknown
): { [key in K]: U } {
  const res: Partial<{ [key in K]: U }> = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      res[key] = fn.call(contextObj, obj[key], key, obj);
    }
  }
  return res as { [key in K]: U };
}

/**
 * Deep equal two objects. Support Arrays and Objects.
 */
export function deepEqual(a: object, b: object): boolean {
  if (a === b) {
    return true;
  }

  const aKeys = Object.keys(a);
  const bKeys = Object.keys(b);
  for (const k of aKeys) {
    if (!bKeys.includes(k)) {
      return false;
    }

    const aProp = (a as Record<string, unknown="">)[k];
    const bProp = (b Kayıt olarak<string, unknown="">)[k];
    if (isObject(aProp) && isObject(bProp)) {
      if (!deepEqual(aProp, bProp)) {
        return false;
      }
    } else if (aProp !== bProp) {
      return false;
    }
  }

  for (const k of bKeys) {
    if (!aKeys.includes(k)) {
      return false;
    }
  }
  return true;
}

function isObject(thing: unknown): thing is object {
  return thing !== null && typeof thing === 'object';
}
</string,></string,></k></t></t>