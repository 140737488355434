/**
 * @license
 * Telif Hakkı 2021 Google LLC
 *
 * Apache Lisansı, Sürüm 2.0 ("Lisans") altında lisanslanmıştır;
 * Bu dosyayı Lisansa uygun olmadıkça kullanamazsınız.
 * Lisansın bir kopyasını şu adresten edinebilirsiniz:
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Yürürlükteki yasalar gerektirmedikçe veya yazılı olarak kabul edilmedikçe, yazılım
 * Lisans kapsamında dağıtılan "OLDUĞU GİBİ" esasına göre dağıtılır,
 * AÇIK veya ZIMNİ HİÇBİR GARANTİ VEYA KOŞUL OLMAKSIZIN.
 * İzinleri yöneten belirli bir dil için Lisansa bakın ve
 * Lisans kapsamındaki sınırlamalar.
 */

dışa aktarma arayüzü Compat<t> {
  _delegate: T;
}

export function getModularInstance<expservice>(
  Servis: Compat<expservice> | ExpService
): ExpService {
  if (service && (service as Compat<expservice>)._delegate) {
    return (Compat olarak hizmet)<expservice>)._delegate;
  } else {
    return service as ExpService;
  }
}
</expservice></expservice></expservice></expservice></t>